var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.trl('ATTRIBUTES_MANAGE_menu')))]),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-list',{staticClass:"mt-4",attrs:{"dense":""}},_vm._l((_vm.groupedByKind),function(values,name,index){return _c('v-list-item-group',{key:name,staticClass:"mb-4",attrs:{"color":"primary"}},[_c('v-subheader',[_vm._v(_vm._s(_vm.trl(`attributes_${name}_plural`)))]),_vm._l((values),function(item){return _c('v-list-item',{key:item.id,attrs:{"to":{
                      name: 'AttributesForm',
                      params: { id: item.id },
                      }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(" mdi-"+_vm._s(item.active ? "checkbox-marked-outline" : "checkbox-blank-outline"))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-size":".9rem"}},[_vm._v(_vm._s(item.value))])],1)],1)}),(index < (Object.keys(_vm.groupedByKind).length -1))?_c('v-divider'):_vm._e()],2)}),1)],1),_c('v-col',{staticClass:"ml-8 mt-8"},[(!_vm.$route.params.id)?_c('div',[_vm._v("Wähle eine Kategorie auf der linken Seite.")]):_vm._e(),_c('router-view')],1)],1),(_vm.current.permissions.DIVISIONS_WRITE)?_c('v-btn',{staticClass:"fab-new",attrs:{"color":"primary","elevation":"4","fab":"","large":"","to":{
      name: 'AttributesForm',
      params: { id: 'new' },
    }}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }