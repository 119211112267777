<template>
  <v-container>
      
      <h1>{{ trl('ATTRIBUTES_MANAGE_menu') }}</h1>
        <v-row>
            <v-col cols="3">

    <v-list dense class="mt-4">
            <v-list-item-group class="mb-4" v-for="(values, name, index) in groupedByKind" :key="name" color="primary">
                <v-subheader>{{ trl(`attributes_${name}_plural`) }}</v-subheader>
                <v-list-item 
                    v-for="item in values" :key="item.id" 
                    :to="{
                        name: 'AttributesForm',
                        params: { id: item.id },
                        }"
                >
                    <v-list-item-icon>
                        <v-icon> mdi-{{ item.active ? "checkbox-marked-outline" : "checkbox-blank-outline" }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                    <v-list-item-title style="font-size:.9rem">{{ item.value }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider v-if="index < (Object.keys(groupedByKind).length -1)"></v-divider>
            </v-list-item-group>
        </v-list>

            </v-col>
            <v-col class="ml-8 mt-8">
                <div v-if="!$route.params.id">Wähle eine Kategorie auf der linken Seite.</div>
                <router-view />
            </v-col>
        </v-row>
        
         <v-btn
      v-if="current.permissions.DIVISIONS_WRITE"
      color="primary"
      elevation="4"
      fab
      large
      :to="{
        name: 'AttributesForm',
        params: { id: 'new' },
      }"
      class="fab-new"
      ><v-icon>mdi-plus</v-icon></v-btn
    >
       
  </v-container>
</template>

<script>
import { trl } from '@/utils/strings'
import { mapGetters } from 'vuex'

export default {
    methods:{trl},
    components:{
        
    },
    computed:{
        ...mapGetters(["current", "attributes"]),
        groupedByKind(){
            return this.attributes.reduce((pre, cur) => {
                if (!(cur.kind in pre)) pre[cur.kind] = []
                pre[cur.kind].push(cur)
                return pre
            }, {})
        }
    },
    created(){
        this.$store.dispatch("req", {route:"attributes", mutation:"attributes"});
    }
}
</script>

<style>

</style>